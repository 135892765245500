:root {
  --var-outlineWidth: 4px;
  --var-newBreakpoints-m: 1024px;
  --var-newBreakpoints-l: 1280px;
  --var-newBreakpoints-xl: 1330px;
  --var-fontFamily-text: 'Inter';
  --var-fontFamily-code: 'Roboto Mono';
  --var-fontWeight-normal: 400;
  --var-fontWeight-medium: 500;
  --var-fontWeight-semiBold: 600;
  --var-fontWeight-bold: 700;
  --var-display-xxl-fontSize: 72px;
  --var-display-xxl-lineHeight: 90px;
  --var-display-xl-fontSize: 60px;
  --var-display-xl-lineHeight: 72px;
  --var-display-lg-fontSize: 48px;
  --var-display-lg-lineHeight: 60px;
  --var-display-md-fontSize: 36px;
  --var-display-md-lineHeight: 44px;
  --var-display-sm-fontSize: 30px;
  --var-display-sm-lineHeight: 38px;
  --var-display-xs-fontSize: 24px;
  --var-display-xs-lineHeight: 32px;
  --var-text-xl-fontSize: 20px;
  --var-text-xl-lineHeight: 30px;
  --var-text-lg-fontSize: 18px;
  --var-text-lg-lineHeight: 28px;
  --var-text-md-fontSize: 16px;
  --var-text-md-lineHeight: 24px;
  --var-text-sm-fontSize: 14px;
  --var-text-sm-lineHeight: 20px;
  --var-text-xs-fontSize: 12px;
  --var-text-xs-lineHeight: 18px;
  --var-text-xxs-fontSize: 10px;
  --var-text-xxs-lineHeight: 16px;
  --var-code-sm-fontSize: 14px;
  --var-code-sm-lineHeight: 16px;
  --var-code-xs-fontSize: 12px;
  --var-code-xs-lineHeight: 16px;
  --var-shadows-xs: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  --var-shadows-sm: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  --var-shadows-md: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.1);
  --var-shadows-lg: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
  --var-shadows-xl: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
  --var-shadows-xxl: 0 24px 28px -12px rgba(16, 24, 40, 0.18);
  --var-shadows-xxxl: 0 32px 64px -12px rgba(16, 24, 40, 0.14);
  --var-shadows-skeumorphic: var(--var-shadows-xs), 0px -1px 0px 0px rgba(16, 24, 40, 0.05) inset;
  --var-shadows-skeumorphicXs: var(--var-shadows-xs), 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset;
  --var-shadows-primaryButtonHover: var(--var-shadows-xs), 0px -2px 0px 0px rgba(15, 24, 40, 0.05) inset;
  --var-effects-primaryButtonBG-default: linear-gradient(var(--var-colors-brand600), var(--var-colors-brand600)) padding-box, linear-gradient(180deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0)) border-box, linear-gradient(180deg, var(--var-colors-brand600), var(--var-colors-brand600)) border-box;
  --var-effects-primaryButtonBG-defaultHover: linear-gradient(var(--var-colors-brand700), var(--var-colors-brand700)) padding-box, linear-gradient(180deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0)) border-box, linear-gradient(180deg, var(--var-colors-brand700), var(--var-colors-brand700)) border-box;
  --var-effects-primaryButtonBG-error: linear-gradient(var(--var-colors-error600), var(--var-colors-error600)) padding-box, linear-gradient(180deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0)) border-box, linear-gradient(180deg, var(--var-colors-error600), var(--var-colors-error600)) border-box;
  --var-effects-primaryButtonBG-errorHover: linear-gradient(var(--var-colors-error700), var(--var-colors-error700)) padding-box, linear-gradient(180deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0)) border-box, linear-gradient(180deg, var(--var-colors-error700), var(--var-colors-error700)) border-box;
  --var-focusRing-color: 0 0 0 2px var(--var-colors-white), 0 0 0 4px var(--var-colors-brand500);
  --var-focusRing-shadow-xs: var(--var-focusRing-color), 0 0 0 2px var(--var-colors-white), var(--var-shadows-xs);
  --var-focusRing-shadow-sm: var(--var-focusRing-color), 0 0 0 2px var(--var-colors-white), var(--var-shadows-sm);
  --var-focusRing-shadow-skeumorphic: var(--var-focusRing-color), var(--var-shadows-skeumorphic);
  --var-focusRing-error-color: 0 0 0 2px var(--var-colors-white), 0 0 0 4px var(--var-colors-error500);
  --var-focusRing-error-shadow-xs: var(--var-focusRing-error-color), 0 0 0 2px var(--var-colors-white), var(--var-shadows-xs);
  --var-focusRing-error-shadow-skeumorphic: var(--var-focusRing-error-color), var(--var-shadows-skeumorphic);
  --var-backgroundBlur-xs: blur(4px);
  --var-backgroundBlur-sm: blur(8px);
  --var-backgroundBlur-md: blur(16px);
  --var-backgroundBlur-lg: blur(24px);
  --var-backgroundBlur-xl: blur(40px);
  --var-colorShades-x0: 0;
  --var-colorShades-x25: 25;
  --var-colorShades-x50: 50;
  --var-colorShades-x100: 100;
  --var-colorShades-x200: 200;
  --var-colorShades-x300: 300;
  --var-colorShades-x400: 400;
  --var-colorShades-x500: 500;
  --var-colorShades-x600: 600;
  --var-colorShades-x700: 700;
  --var-colorShades-x800: 800;
  --var-colorShades-x900: 900;
  --var-colorShades-x950: 950;
  --var-spaces-none: 0px;
  --var-spaces-x1: 1px;
  --var-spaces-x2: 2px;
  --var-spaces-x3: 3px;
  --var-spaces-x4: 4px;
  --var-spaces-x6: 6px;
  --var-spaces-x8: 8px;
  --var-spaces-x10: 10px;
  --var-spaces-x12: 12px;
  --var-spaces-x14: 14px;
  --var-spaces-x16: 16px;
  --var-spaces-x20: 20px;
  --var-spaces-x24: 24px;
  --var-spaces-x28: 28px;
  --var-spaces-x32: 32px;
  --var-spaces-x36: 36px;
  --var-spaces-x40: 40px;
  --var-spaces-x44: 44px;
  --var-spaces-x48: 48px;
  --var-spaces-x56: 56px;
  --var-spaces-x64: 64px;
  --var-spaces-x80: 80px;
  --var-spaces-x96: 96px;
  --var-spaces-x128: 128px;
  --var-spaces-x160: 160px;
  --var-fontSizes-x10: 10px;
  --var-fontSizes-x12: 12px;
  --var-fontSizes-x13: 13px;
  --var-fontSizes-x14: 14px;
  --var-fontSizes-x15: 15px;
  --var-fontSizes-x16: 16px;
  --var-fontSizes-x18: 18px;
  --var-fontSizes-x20: 20px;
  --var-fontSizes-x22: 22px;
  --var-fontSizes-x24: 24px;
  --var-fontSizes-x28: 28px;
  --var-fontSizes-x30: 30px;
  --var-fontSizes-x36: 36px;
  --var-fontSizes-x48: 48px;
  --var-fontSizes-x60: 60px;
  --var-fontSizes-x72: 72px;
  --var-lineHeight-auto: normal;
  --var-lineHeight-x16: 16px;
  --var-lineHeight-x18: 18px;
  --var-lineHeight-x19: 19px;
  --var-lineHeight-x20: 20px;
  --var-lineHeight-x21: 21px;
  --var-lineHeight-x22: 22px;
  --var-lineHeight-x24: 24px;
  --var-lineHeight-x28: 28px;
  --var-lineHeight-x30: 30px;
  --var-lineHeight-x32: 32px;
  --var-lineHeight-x38: 38px;
  --var-lineHeight-x44: 44px;
  --var-lineHeight-x60: 60px;
  --var-lineHeight-x72: 72px;
  --var-lineHeight-x90: 90px;
  --var-sizes-x4: 4px;
  --var-sizes-x6: 6px;
  --var-sizes-x8: 8px;
  --var-sizes-x10: 10px;
  --var-sizes-x12: 12px;
  --var-sizes-x14: 14px;
  --var-sizes-x16: 16px;
  --var-sizes-x18: 18px;
  --var-sizes-x20: 20px;
  --var-sizes-x22: 22px;
  --var-sizes-x24: 24px;
  --var-sizes-x28: 28px;
  --var-sizes-x30: 30px;
  --var-sizes-x32: 32px;
  --var-sizes-x36: 36px;
  --var-sizes-x40: 40px;
  --var-sizes-x44: 44px;
  --var-sizes-x45: 45px;
  --var-sizes-x48: 48px;
  --var-sizes-x56: 56px;
  --var-sizes-x64: 64px;
  --var-radius-none: 0px;
  --var-radius-x2: 2px;
  --var-radius-x4: 4px;
  --var-radius-x6: 6px;
  --var-radius-x8: 8px;
  --var-radius-x10: 10px;
  --var-radius-x12: 12px;
  --var-radius-x16: 16px;
  --var-radius-x20: 20px;
  --var-radius-x24: 24px;
  --var-radius-x32: 32px;
  --var-radius-full: 9999px;
  --var-width-x320: 320px;
  --var-width-x384: 384px;
  --var-width-x480: 480px;
  --var-width-x560: 560px;
  --var-width-x640: 640px;
  --var-width-x768: 768px;
  --var-width-x1024: 1024px;
  --var-width-x1280: 1280px;
  --var-width-x1440: 1440px;
  --var-width-x1600: 1600px;
  --var-width-x1920: 1920px;
  --var-paragraphMaxWidth: 720px;
  --var-grid-desktop-containerWidth: 1280px;
  --var-grid-desktop-contentWidth: 1216px;
  --var-grid-desktop-gutter: 32px;
  --var-grid-tablet-containerWidth: 768px;
  --var-grid-tablet-contentWidth: 704px;
  --var-grid-tablet-gutter: 32px;
  --var-grid-mobile-containerWidth: 375px;
  --var-grid-mobile-contentWidth: 343px;
  --var-grid-mobile-gutter: 16px;
  --var-colors-white: #ffffff;
  --var-colors-black: #000000;
  --var-colors-transparent: #ffffff00;
  --var-colors-gray25: #fcfcfd;
  --var-colors-gray50: #f9fafb;
  --var-colors-gray100: #f2f4f7;
  --var-colors-gray200: #e4e7ec;
  --var-colors-gray300: #d0d5dd;
  --var-colors-gray400: #98a2b3;
  --var-colors-gray500: #667085;
  --var-colors-gray600: #475467;
  --var-colors-gray700: #344054;
  --var-colors-gray800: #182230;
  --var-colors-gray900: #101828;
  --var-colors-gray950: #0c111d;
  --var-colors-brand25: #f7f8fe;
  --var-colors-brand50: #eff2fe;
  --var-colors-brand100: #e1e7fd;
  --var-colors-brand200: #c9d2fa;
  --var-colors-brand300: #a7b5f6;
  --var-colors-brand400: #828ef1;
  --var-colors-brand500: #626ae9;
  --var-colors-brand600: #4c4ddc;
  --var-colors-brand700: #403fc2;
  --var-colors-brand800: #35359d;
  --var-colors-brand900: #30317c;
  --var-colors-brand950: #2b2d5b;
  --var-colors-error25: #fffbfa;
  --var-colors-error50: #fef3f2;
  --var-colors-error100: #fee4e2;
  --var-colors-error200: #fecdca;
  --var-colors-error300: #fda29b;
  --var-colors-error400: #f97066;
  --var-colors-error500: #f04438;
  --var-colors-error600: #d92d20;
  --var-colors-error700: #b42318;
  --var-colors-error800: #912018;
  --var-colors-error900: #7a271a;
  --var-colors-error950: #55160c;
  --var-colors-warning25: #fffcf5;
  --var-colors-warning50: #fffaeb;
  --var-colors-warning100: #fef0c7;
  --var-colors-warning200: #fedf89;
  --var-colors-warning300: #fec84b;
  --var-colors-warning400: #fdb022;
  --var-colors-warning500: #f79009;
  --var-colors-warning600: #dc6803;
  --var-colors-warning700: #b54708;
  --var-colors-warning800: #93370d;
  --var-colors-warning900: #7a2e0e;
  --var-colors-warning950: #4e1d09;
  --var-colors-success25: #f6fef9;
  --var-colors-success50: #ecfdf3;
  --var-colors-success100: #d1fadf;
  --var-colors-success200: #a6f4c5;
  --var-colors-success300: #6ce9a6;
  --var-colors-success400: #32d583;
  --var-colors-success500: #12b76a;
  --var-colors-success600: #039855;
  --var-colors-success700: #027a48;
  --var-colors-success800: #05603a;
  --var-colors-success900: #054f31;
  --var-colors-success950: #053321;
  --var-colors-grayBlue25: #fcfcfd;
  --var-colors-grayBlue50: #f8f9fc;
  --var-colors-grayBlue100: #eaecf5;
  --var-colors-grayBlue200: #e0e4f0;
  --var-colors-grayBlue300: #b3b8db;
  --var-colors-grayBlue400: #717bbc;
  --var-colors-grayBlue500: #4e58ab;
  --var-colors-grayBlue600: #3e4784;
  --var-colors-grayBlue700: #2e3772;
  --var-colors-grayBlue800: #293056;
  --var-colors-grayBlue900: #101923;
  --var-colors-grayBlue950: #0d0f1c;
  --var-colors-moss25: #f9fdf7;
  --var-colors-moss50: #f5fbee;
  --var-colors-moss100: #e6f4d7;
  --var-colors-moss200: #ceeab0;
  --var-colors-moss300: #acdc79;
  --var-colors-moss400: #8bcb3c;
  --var-colors-moss500: #68bf2a;
  --var-colors-moss600: #4e7a21;
  --var-colors-moss700: #3f621a;
  --var-colors-moss800: #335015;
  --var-colors-moss900: #284212;
  --var-colors-moss950: #1a2b0b;
  --var-colors-greenLight25: #f9fef5;
  --var-colors-greenLight50: #f3fee7;
  --var-colors-greenLight100: #e4fbcc;
  --var-colors-greenLight200: #d0f8ab;
  --var-colors-greenLight300: #a6ef67;
  --var-colors-greenLight400: #85e13a;
  --var-colors-greenLight500: #65c01c;
  --var-colors-greenLight600: #4ca30d;
  --var-colors-greenLight700: #3b7c0f;
  --var-colors-greenLight800: #326212;
  --var-colors-greenLight900: #285314;
  --var-colors-greenLight950: #25280a;
  --var-colors-green25: #f9fef9;
  --var-colors-green50: #f0fcf2;
  --var-colors-green100: #d3fbdf;
  --var-colors-green200: #a4f0ca;
  --var-colors-green300: #73e2a3;
  --var-colors-green400: #3dcb7f;
  --var-colors-green500: #16b364;
  --var-colors-green600: #099250;
  --var-colors-green700: #087a43;
  --var-colors-green800: #095c37;
  --var-colors-green900: #084c2e;
  --var-colors-green950: #05221c;
  --var-colors-teal25: #f6fefc;
  --var-colors-teal50: #f0fcf9;
  --var-colors-teal100: #ccf8ef;
  --var-colors-teal200: #99f6e0;
  --var-colors-teal300: #5fe8dd;
  --var-colors-teal400: #2ed3b7;
  --var-colors-teal500: #15b79e;
  --var-colors-teal600: #0e9384;
  --var-colors-teal700: #107569;
  --var-colors-teal800: #125d56;
  --var-colors-teal900: #134e48;
  --var-colors-teal950: #0a2926;
  --var-colors-cyan25: #f9feff;
  --var-colors-cyan50: #ecfdff;
  --var-colors-cyan100: #cff9fe;
  --var-colors-cyan200: #a5f0fc;
  --var-colors-cyan300: #67e3f9;
  --var-colors-cyan400: #22ccee;
  --var-colors-cyan500: #06aed4;
  --var-colors-cyan600: #088ab2;
  --var-colors-cyan700: #0e7090;
  --var-colors-cyan800: #155b75;
  --var-colors-cyan900: #164c63;
  --var-colors-cyan950: #0d2d3a;
  --var-colors-blueLight25: #f9fbff;
  --var-colors-blueLight50: #f5f9ff;
  --var-colors-blueLight100: #edf2fe;
  --var-colors-blueLight200: #b6e0fe;
  --var-colors-blueLight300: #7cd4fd;
  --var-colors-blueLight400: #36bffa;
  --var-colors-blueLight500: #0ba5ec;
  --var-colors-blueLight600: #0086c9;
  --var-colors-blueLight700: #026aa2;
  --var-colors-blueLight800: #065986;
  --var-colors-blueLight900: #0b4a6f;
  --var-colors-blueLight950: #062c41;
  --var-colors-blue25: #f5faff;
  --var-colors-blue50: #eff8ff;
  --var-colors-blue100: #d1e9ff;
  --var-colors-blue200: #b2ddff;
  --var-colors-blue300: #84caff;
  --var-colors-blue400: #53b1fd;
  --var-colors-blue500: #2e90fa;
  --var-colors-blue600: #1570ef;
  --var-colors-blue700: #175cd3;
  --var-colors-blue800: #1849a9;
  --var-colors-blue900: #194185;
  --var-colors-blue950: #102a56;
  --var-colors-blueDark25: #f5f8ff;
  --var-colors-blueDark50: #e7f5ff;
  --var-colors-blueDark100: #d1e0ff;
  --var-colors-blueDark200: #b2ccff;
  --var-colors-blueDark300: #84adff;
  --var-colors-blueDark400: #528bff;
  --var-colors-blueDark500: #2970ff;
  --var-colors-blueDark600: #155eef;
  --var-colors-blueDark700: #004eeb;
  --var-colors-blueDark800: #0040c1;
  --var-colors-blueDark900: #00359e;
  --var-colors-blueDark950: #002266;
  --var-colors-indigo25: #f5f8ff;
  --var-colors-indigo50: #eef4ff;
  --var-colors-indigo100: #e0e9ff;
  --var-colors-indigo200: #c7d7ff;
  --var-colors-indigo300: #a4bcfd;
  --var-colors-indigo400: #8098f9;
  --var-colors-indigo500: #6172f3;
  --var-colors-indigo600: #444ce7;
  --var-colors-indigo700: #3538cd;
  --var-colors-indigo800: #2d31a6;
  --var-colors-indigo900: #2d3282;
  --var-colors-indigo950: #1f235b;
  --var-colors-violet25: #fbfaff;
  --var-colors-violet50: #f5f3ff;
  --var-colors-violet100: #ece9fe;
  --var-colors-violet200: #ddd6fe;
  --var-colors-violet300: #c3b5fd;
  --var-colors-violet400: #a5b4fb;
  --var-colors-violet500: #875bf7;
  --var-colors-violet600: #7839ee;
  --var-colors-violet700: #6927da;
  --var-colors-violet800: #5720b7;
  --var-colors-violet900: #4b1c96;
  --var-colors-violet950: #2e125e;
  --var-colors-purple25: #fafaff;
  --var-colors-purple50: #f4f3ff;
  --var-colors-purple100: #ebe9fe;
  --var-colors-purple200: #d9d6fe;
  --var-colors-purple300: #bdb4fe;
  --var-colors-purple400: #9b8afb;
  --var-colors-purple500: #7a5afb;
  --var-colors-purple600: #6938ef;
  --var-colors-purple700: #5925dc;
  --var-colors-purple800: #4a1fb8;
  --var-colors-purple900: #3e1c96;
  --var-colors-purple950: #27115f;
  --var-colors-fuchsia25: #fefaff;
  --var-colors-fuchsia50: #fdf4ff;
  --var-colors-fuchsia100: #fbe8ff;
  --var-colors-fuchsia200: #f6d0fe;
  --var-colors-fuchsia300: #eeaffd;
  --var-colors-fuchsia400: #e478fa;
  --var-colors-fuchsia500: #d444f1;
  --var-colors-fuchsia600: #ba24d5;
  --var-colors-fuchsia700: #9f1ab1;
  --var-colors-fuchsia800: #821b90;
  --var-colors-fuchsia900: #6f1b77;
  --var-colors-fuchsia950: #47104c;
  --var-colors-pink25: #fefbfb;
  --var-colors-pink50: #fdf2fa;
  --var-colors-pink100: #fce7f6;
  --var-colors-pink200: #fccee8;
  --var-colors-pink300: #faa7e0;
  --var-colors-pink400: #fe70c7;
  --var-colors-pink500: #ee46bc;
  --var-colors-pink600: #dd2590;
  --var-colors-pink700: #c11574;
  --var-colors-pink800: #9e165f;
  --var-colors-pink900: #851651;
  --var-colors-pink950: #4e0d30;
  --var-colors-rose25: #fff5f6;
  --var-colors-rose50: #fff1f3;
  --var-colors-rose100: #ffe4eb;
  --var-colors-rose200: #fecdd6;
  --var-colors-rose300: #fea3b4;
  --var-colors-rose400: #fd6f8e;
  --var-colors-rose500: #e53d68;
  --var-colors-rose600: #e31b54;
  --var-colors-rose700: #c01048;
  --var-colors-rose800: #a11043;
  --var-colors-rose900: #89123e;
  --var-colors-rose950: #510b24;
  --var-colors-orangeDark25: #fff9f5;
  --var-colors-orangeDark50: #fff4ed;
  --var-colors-orangeDark100: #ffe6d5;
  --var-colors-orangeDark200: #ffd6ae;
  --var-colors-orangeDark300: #ff9c66;
  --var-colors-orangeDark400: #ff692e;
  --var-colors-orangeDark500: #ff4405;
  --var-colors-orangeDark600: #e62e05;
  --var-colors-orangeDark700: #bc1b06;
  --var-colors-orangeDark800: #971b0c;
  --var-colors-orangeDark900: #771a0d;
  --var-colors-orangeDark950: #57130a;
  --var-colors-orange25: #fefaf5;
  --var-colors-orange50: #fefaee;
  --var-colors-orange100: #fdead7;
  --var-colors-orange200: #fe9baf;
  --var-colors-orange300: #ff7b27;
  --var-colors-orange400: #f63744;
  --var-colors-orange500: #fe6820;
  --var-colors-orange600: #e04f16;
  --var-colors-orange700: #b93815;
  --var-colors-orange800: #932f19;
  --var-colors-orange900: #772917;
  --var-colors-orange950: #511c10;
  --var-colors-yellow25: #fefdf0;
  --var-colors-yellow50: #fefbe8;
  --var-colors-yellow100: #fef7c3;
  --var-colors-yellow200: #feee95;
  --var-colors-yellow300: #fde272;
  --var-colors-yellow400: #fac515;
  --var-colors-yellow500: #eab308;
  --var-colors-yellow600: #ca8504;
  --var-colors-yellow700: #a15c07;
  --var-colors-yellow800: #854a0e;
  --var-colors-yellow900: #713b12;
  --var-colors-yellow950: #542c0d;
  --var-alphaColor-whiteAlpha10: rgba(255, 255, 255, 0.1);
  --var-alphaColor-whiteAlpha20: rgba(255, 255, 255, 0.2);
  --var-alphaColor-whiteAlpha30: rgba(255, 255, 255, 0.3);
  --var-alphaColor-whiteAlpha40: rgba(255, 255, 255, 0.4);
  --var-alphaColor-whiteAlpha50: rgba(255, 255, 255, 0.5);
  --var-alphaColor-whiteAlpha60: rgba(255, 255, 255, 0.6);
  --var-alphaColor-whiteAlpha70: rgba(255, 255, 255, 0.7);
  --var-alphaColor-whiteAlpha80: rgba(255, 255, 255, 0.8);
  --var-alphaColor-whiteAlpha90: rgba(255, 255, 255, 0.9);
  --var-alphaColor-whiteAlpha100: rgba(255, 255, 255, 1);
  --var-alphaColor-blackAlpha10: rgba(0, 0, 0, 0.1);
  --var-alphaColor-blackAlpha20: rgba(0, 0, 0, 0.2);
  --var-alphaColor-blackAlpha30: rgba(0, 0, 0, 0.3);
  --var-alphaColor-blackAlpha40: rgba(0, 0, 0, 0.4);
  --var-alphaColor-blackAlpha50: rgba(0, 0, 0, 0.5);
  --var-alphaColor-blackAlpha60: rgba(0, 0, 0, 0.6);
  --var-alphaColor-blackAlpha70: rgba(0, 0, 0, 0.7);
  --var-alphaColor-blackAlpha80: rgba(0, 0, 0, 0.8);
  --var-alphaColor-blackAlpha90: rgba(0, 0, 0, 0.9);
  --var-alphaColor-blackAlpha100: rgba(0, 0, 0, 1);
  --var-gradient-gray600To500: linear-gradient(90deg, #475467 0%, #667085 100%);
  --var-gradient-gray700To600: linear-gradient(45deg, #344054 0%, #475467 100%);
  --var-gradient-gray800To600: linear-gradient(45deg, #1a222e 0%, #475467 100%);
  --var-gradient-gray800To700: linear-gradient(90deg, #1a222e 0%, #344054 100%);
  --var-gradient-gray900To600: linear-gradient(26.5deg, #101828 0%, #475467 100%);
  --var-gradient-gray900To700: linear-gradient(45deg, #101828 0%, #344054 100%);
  --var-gradient-brand600To500: linear-gradient(90deg, #7f56d9 0%, #000000 100%);
  --var-gradient-brand700To600: linear-gradient(45deg, #6941c6 0%, #7f56d9 100%);
  --var-gradient-brand800To600: linear-gradient(45deg, #53389e 0%, #7f56d9 100%);
  --var-gradient-brand800To700: linear-gradient(90deg, #53389e 0%, #6941c6 100%);
  --var-gradient-brand900To600: linear-gradient(26.5deg, #42307d 0%, #7f56d9 100%);
  --var-gradient-brand900To700: linear-gradient(45deg, #42307d 0%, #6941c6 100%);
  --var-gradient-neutral01: linear-gradient(180deg, #ffffff 0%, #f3f5f7 100%);
  --var-gradient-neutral02: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
  --var-gradient-neutral03: linear-gradient(180deg, #edf0f3 0%, #e7ebef 100%);
  --var-gradient-neutral04: linear-gradient(180deg, #f3f5f7 0%, #e0e5eb 100%);
  --var-gradient-neutral05: linear-gradient(180deg, #edf0f3 0%, #d4dbe2 100%);
  --var-gradient-neutral06: linear-gradient(180deg, #e7ebef 0%, #c8d1da 100%);
  --var-gradient-neutral07: linear-gradient(180deg, #e7ebef 0%, #c8d1da 100%);
  --var-gradient-gradient01: linear-gradient(180deg, #a5c0ee 0%, #fbc5ec 100%);
  --var-gradient-gradient02: linear-gradient(180deg, #fbc2eb 0%, #a1bcd1 100%);
  --var-gradient-gradient03: linear-gradient(180deg, #ffd1ff 0%, #fad0c4 100%);
  --var-gradient-gradient04: linear-gradient(225deg, #fad0c4 0%, #ff9a9e 100%);
  --var-gradient-gradient05: linear-gradient(270deg, #fcb69f 0%, #ffecd2 100%);
  --var-gradient-gradient06: linear-gradient(180deg, #fecfef 0%, #ff989c 100%);
  --var-gradient-gradient07: linear-gradient(45deg, #ff9de4 0%, #ffeaf6 100%);
  --var-gradient-gradient08: linear-gradient(180deg, #e6dee9 0%, #fdcaf1 100%);
  --var-gradient-gradient09: linear-gradient(45deg, #a6c0fe 0%, #ffeafe 100%);
  --var-gradient-gradient10: linear-gradient(0deg, #cfc7f8 0%, #ebbba7 100%);
  --var-gradient-gradient11: linear-gradient(135deg, #fccb90 0%, #d57eeb 100%);
  --var-gradient-gradient12: linear-gradient(45deg, #7b6ae0 0%, #ffbb89 100%);
  --var-gradient-gradient13: linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%);
  --var-gradient-gradient14: linear-gradient(0deg, #ffde63 0%, #a8edea 100%);
  --var-gradient-gradient15: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --var-gradient-gradient16: linear-gradient(45deg, #dfd1c5 0%, #fff6eb 100%);
  --var-gradient-gradient17: linear-gradient(135deg, #fff6b7 0%, #fb758a 100%);
  --var-gradient-gradient18: linear-gradient(45deg, #ff7ec7 0%, #ffed46 100%);
  --var-gradient-gradient19: linear-gradient(0deg, #feafa8 0%, #f5efef 100%);
  --var-gradient-gradient20: linear-gradient(45deg, #fbfcdb 0%, #e9defa 100%);
  --var-gradient-gradient21: linear-gradient(0deg, #fff1eb 0%, #ace0f9 100%);
  --var-gradient-gradient22: linear-gradient(0deg, #c1dfc4 0%, #deecdd 100%);
  --var-gradient-gradient23: linear-gradient(45deg, #a1c4fd 0%, #c2e9fb 100%);
  --var-gradient-gradient24: linear-gradient(0deg, #accbee 0%, #e7f0fd 100%);
  --var-gradient-gradient25: linear-gradient(0deg, #84fab0 0%, #accbee 100%);
  --var-gradient-gradient26: linear-gradient(45deg, #39a0ff 0%, #8fff85 100%);
  --var-gradient-gradient27: linear-gradient(270deg, #74ebd5 0%, #9face6 100%);
  --var-gradient-gradient28: linear-gradient(45deg, #4a879a 0%, #c5edf5 100%);
  --var-gradient-gradient29: linear-gradient(45deg, #989de3 0%, #b1f4cf 100%);
  --var-gradient-gradient30: linear-gradient(45deg, #7cdada 0%, #f697aa 100%);
  --var-gradient-gradient31: linear-gradient(45deg, #b1ff96 0%, #ffadf7 100%);
  --var-gradient-gradient32: linear-gradient(0deg, #96fbc4 0%, #f9f586 100%);
  --var-gradient-gradient33: linear-gradient(45deg, #4def8e 0%, #ffeb3a 100%);
  --var-gradient-gradient34: linear-gradient(135deg, #f0ff00 0%, #58cffb 100%);
  --var-gradient-gradient35: linear-gradient(0deg, #d1fdff 0%, #fddb92 100%);
  --var-gradient-gradient36: linear-gradient(0deg, #ebc0fd 0%, #d9ded8 100%);
  --var-gradient-gradient37: linear-gradient(45deg, #ffa4f6 0%, #b7dcff 100%);
  --var-gradient-gradient38: linear-gradient(0deg, #cd9cf2 0%, #f6f3ff 100%);
  --var-gradient-gradient39: linear-gradient(315deg, #f5c8f5 0%, #daddfa 100%);
  --var-gradient-gradient40: linear-gradient(0deg, #e6dee9 0%, #bdc2e8 100%);
  --var-gradient-gradient41: linear-gradient(0deg, #6a85b6 0%, #bac8e0 100%);
  --var-gradient-gradient42: linear-gradient(45deg, #8b8b8b 0%, #eaeaea 100%);
  --var-gradient-gradient43: linear-gradient(135deg, #e2b0ff 0%, #9f44d3 100%);
  --var-gradient-gradient44: linear-gradient(135deg, #ce9ffc 0%, #7367f0 100%);
  --var-gradient-gradient45: linear-gradient(135deg, #72edf2 0%, #5151e5 100%);
  --var-gradient-gradient46: linear-gradient(0deg, #a3bded 0%, #6991c7 100%);
  --var-gradient-gradient47: linear-gradient(0deg, #fbc8d4 0%, #9795f0 100%);
  --var-gradient-gradient48: linear-gradient(0deg, #a7a6cb 0%, #8989ba 100%);
  --var-gradient-gradient49: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%);
  --var-gradient-gradient50: linear-gradient(135deg, #81ffef 0%, #f067b4 100%);
  --var-gradient-gradient51: linear-gradient(135deg, #dcb0ed 0%, #9999cc 100%);
  --var-gradient-gradient52: linear-gradient(135deg, #fff5c3 0%, #9452a5 100%);
  --var-gradient-gradient53: linear-gradient(135deg, #f1ca74 0%, #a64db6 100%);
  --var-gradient-gradient54: linear-gradient(45deg, #4d6ad0 0%, #ff9d7e 100%);
  --var-gradient-gradient55: linear-gradient(135deg, #ffcf71 0%, #2376dd 100%);
  --var-gradient-gradient56: linear-gradient(135deg, #e8d07a 0%, #5312d6 100%);
  --var-gradient-gradient57: linear-gradient(180deg, #bfd9fe 0%, #dfb9b5 100%);
  --var-gradient-gradient58: linear-gradient(0deg, #fa71cd 0%, #c471f5 100%);
  --var-gradient-gradient59: linear-gradient(135deg, #43cbff 0%, #9708cc 100%);
  --var-gradient-gradient60: linear-gradient(180deg, #7579ff 0%, #b224ef 100%);
  --var-gradient-gradient61: linear-gradient(45deg, #ad00fe 0%, #00e0ee 100%);
  --var-gradient-gradient62: linear-gradient(135deg, #89f7fe 0%, #66a6ff 100%);
  --var-gradient-gradient63: linear-gradient(0deg, #00eefd 0%, #2af598 100%);
  --var-gradient-gradient64: linear-gradient(45deg, #fffb00 0%, #fff500 100%);
  --var-gradient-gradient65: linear-gradient(135deg, #ffaba8 0%, #fcff00 100%);
  --var-gradient-gradient66: linear-gradient(45deg, #ff7a00 0%, #ffd439 100%);
  --var-gradient-gradient67: linear-gradient(135deg, #ffd3a5 0%, #fd6585 100%);
  --var-gradient-gradient68: linear-gradient(180deg, #f9d423 0%, #e14fad 100%);
  --var-gradient-gradient69: linear-gradient(135deg, #f74fac 0%, #fcb24f 100%);
  --var-gradient-gradient70: linear-gradient(135deg, #f49062 0%, #fd371f 100%);
  --var-gradient-gradient71: linear-gradient(45deg, #ff6c6c 0%, #dd7bff 100%);
  --var-gradient-gradient72: linear-gradient(135deg, #f97794 0%, #623aa2 100%);
  --var-gradient-gradient73: linear-gradient(180deg, #c569cf 0%, #ee609c 100%);
  --var-gradient-gradient74: linear-gradient(0deg, #c7eafd 0%, #e819bb 100%);
  --var-gradient-gradient75: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
  --var-gradient-gradient76: linear-gradient(135deg, #f8ceec 0%, #d939cd 100%);
  --var-gradient-gradient77: linear-gradient(135deg, #ee9ae5 0%, #5961f9 100%);
  --var-gradient-gradient78: linear-gradient(270deg, #6a11cb 0%, #2575fc 100%);
  --var-gradient-gradient79: linear-gradient(45deg, #0017e4 0%, #3793ff 100%);
  --var-gradient-gradient80: linear-gradient(0deg, #00c6fb 0%, #005bea 100%);
  --var-gradient-gradient81: linear-gradient(45deg, #4b73ff 0%, #7cf7ff 100%);
  --var-gradient-gradient82: linear-gradient(135deg, #5efcb8 0%, #736efe 100%);
  --var-gradient-gradient83: linear-gradient(0deg, #7028e4 0%, #e5b2ca 100%);
  --var-gradient-gradient84: linear-gradient(90deg, #7873f5 0%, #ec77ab 100%);
  --var-gradient-gradient85: linear-gradient(135deg, #bd1eff 0%, #e1467c 100%);
  --var-gradient-gradient86: linear-gradient(45deg, #d079ee 0%, #8a88fb 100%);
  --var-gradient-gradient87: linear-gradient(135deg, #c99fff 0%, #981ed2 100%);
  --var-gradient-gradient88: linear-gradient(0deg, #9b23ea 0%, #5f72bd 100%);
  --var-gradient-gradient89: linear-gradient(135deg, #b39fff 0%, #6a1ed2 100%);
  --var-gradient-gradient90: linear-gradient(45deg, #4300b1 0%, #a531dc 100%);
  --var-gradient-gradient91: linear-gradient(315deg, #764ba2 0%, #667eea 100%);
  --var-gradient-bgMenu: linear-gradient(45deg, #a6c0fe 20%, #ffeaf6 100%);
  --var-gradient-listItemHover: linear-gradient(45deg, #a6c0fe 40%, #ffeaf6 100%);
  --var-textColor-primary: var(--var-colors-gray900);
  --var-textColor-primaryOnBrand: var(--var-colors-white);
  --var-textColor-secondary: var(--var-colors-gray700);
  --var-textColor-secondaryHover: var(--var-colors-gray800);
  --var-textColor-secondaryOnBrand: var(--var-colors-brand200);
  --var-textColor-tertiary: var(--var-colors-gray600);
  --var-textColor-tertiaryHover: var(--var-colors-gray700);
  --var-textColor-tertiaryOnBrand: var(--var-colors-brand200);
  --var-textColor-quaternary: var(--var-colors-gray500);
  --var-textColor-quaternaryOnBrand: var(--var-colors-brand300);
  --var-textColor-white: var(--var-colors-white);
  --var-textColor-disabled: var(--var-colors-gray500);
  --var-textColor-placeholder: var(--var-colors-gray500);
  --var-textColor-placeholderSubtle: var(--var-colors-gray300);
  --var-textColor-brandPrimary: var(--var-colors-brand900);
  --var-textColor-brandSecondary: var(--var-colors-brand700);
  --var-textColor-brandTertiary: var(--var-colors-brand600);
  --var-textColor-errorPrimary: var(--var-colors-error600);
  --var-textColor-warningPrimary: var(--var-colors-warning600);
  --var-textColor-successPrimary: var(--var-colors-success600);
  --var-borderColor-primary: var(--var-colors-gray300);
  --var-borderColor-secondary: var(--var-colors-gray200);
  --var-borderColor-tertiary: var(--var-colors-gray100);
  --var-borderColor-disabled: var(--var-colors-gray300);
  --var-borderColor-disabledSubtle: var(--var-colors-gray200);
  --var-borderColor-brand: var(--var-colors-brand500);
  --var-borderColor-brandAlt: var(--var-colors-brand600);
  --var-borderColor-error: var(--var-colors-error500);
  --var-borderColor-errorSubtle: var(--var-colors-error300);
  --var-foregroundColor-primary: var(--var-colors-gray900);
  --var-foregroundColor-secondary: var(--var-colors-gray700);
  --var-foregroundColor-secondaryHover: var(--var-colors-gray800);
  --var-foregroundColor-tertiary: var(--var-colors-gray600);
  --var-foregroundColor-tertiaryHover: var(--var-colors-gray700);
  --var-foregroundColor-quaternary: var(--var-colors-gray500);
  --var-foregroundColor-quaternaryHover: var(--var-colors-gray600);
  --var-foregroundColor-quinary: var(--var-colors-gray400);
  --var-foregroundColor-quinaryHover: var(--var-colors-gray500);
  --var-foregroundColor-senary: var(--var-colors-gray300);
  --var-foregroundColor-white: var(--var-colors-white);
  --var-foregroundColor-disabled: var(--var-colors-gray400);
  --var-foregroundColor-disabledSubtle: var(--var-colors-gray300);
  --var-foregroundColor-brandPrimary: var(--var-colors-brand600);
  --var-foregroundColor-brandSecondary: var(--var-colors-brand500);
  --var-foregroundColor-errorPrimary: var(--var-colors-error600);
  --var-foregroundColor-errorSecondary: var(--var-colors-error500);
  --var-foregroundColor-warningPrimary: var(--var-colors-warning600);
  --var-foregroundColor-warningSecondary: var(--var-colors-warning500);
  --var-foregroundColor-successPrimary: var(--var-colors-success600);
  --var-foregroundColor-successSecondary: var(--var-colors-success500);
  --var-backgroundColor-primary: var(--var-colors-white);
  --var-backgroundColor-primaryAlt: var(--var-colors-white);
  --var-backgroundColor-primaryHover: var(--var-colors-gray50);
  --var-backgroundColor-primarySolid: var(--var-colors-gray950);
  --var-backgroundColor-secondary: var(--var-colors-gray50);
  --var-backgroundColor-secondaryAlt: var(--var-colors-gray50);
  --var-backgroundColor-secondaryHover: var(--var-colors-gray100);
  --var-backgroundColor-secondarySubtle: var(--var-colors-gray25);
  --var-backgroundColor-secondarySolid: var(--var-colors-gray600);
  --var-backgroundColor-tertiary: var(--var-colors-gray100);
  --var-backgroundColor-quaternary: var(--var-colors-gray200);
  --var-backgroundColor-active: var(--var-colors-gray50);
  --var-backgroundColor-disabled: var(--var-colors-gray100);
  --var-backgroundColor-disabledSubtle: var(--var-colors-gray50);
  --var-backgroundColor-overlay: var(--var-colors-gray950);
  --var-backgroundColor-brandPrimary: var(--var-colors-brand50);
  --var-backgroundColor-brandSecondary: var(--var-colors-brand100);
  --var-backgroundColor-brandSolid: var(--var-colors-brand600);
  --var-backgroundColor-brandSolidHover: var(--var-colors-brand700);
  --var-backgroundColor-brandSection: var(--var-colors-brand800);
  --var-backgroundColor-brandSectionSubtle: var(--var-colors-brand700);
  --var-backgroundColor-errorPrimary: var(--var-colors-error50);
  --var-backgroundColor-errorSecondary: var(--var-colors-error100);
  --var-backgroundColor-errorSolid: var(--var-colors-error600);
  --var-backgroundColor-warningPrimary: var(--var-colors-warning50);
  --var-backgroundColor-warningSecondary: var(--var-colors-warning100);
  --var-backgroundColor-warningSolid: var(--var-colors-warning600);
  --var-backgroundColor-successPrimary: var(--var-colors-success50);
  --var-backgroundColor-successSecondary: var(--var-colors-success100);
  --var-backgroundColor-successSolid: var(--var-colors-success600);
  --var-zLayer-xs: 1;
  --var-zLayer-s: 10;
  --var-zLayer-m: 100;
  --var-zLayer-l: 1000;
  --var-zLayer-xl: 10000;
  --var-zLayer-xxl: 100000;
  --var-zLayer-xxxl: 1000000000;
  --var-transition-s: 0.2s ease-in-out;
  --var-transition-m: 0.4s ease-in-out;
  --var-transition-l: 0.6s ease-in-out
}